"use strict";
/* create by Micheal Xiao 2019/7/19 15:56 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
require("./libs/fontawesome");
var styles = __importStar(require("./main.css"));
var config_1 = require("./config");
var utils_1 = require("./utils");
var draw_1 = require("./draw");
var Verify = require('./Verify.pug');
var SlideVerify = /** @class */ (function () {
    function SlideVerify(_a) {
        var elementId = _a.elementId, onSuccess = _a.onSuccess, onFail = _a.onFail, onRefresh = _a.onRefresh, lang = _a.lang, photo = _a.photo, source = _a.source;
        var intlText = {};
        if (lang && lang === 'en') {
            intlText = { slideTips: 'slide to right' };
        }
        else {
            intlText = { slideTips: '向右滑动填充拼图' };
        }
        var conEl = document.getElementById(elementId);
        conEl.innerHTML = Verify({
            slideTips: intlText.slideTips,
            svCanvasHeight: config_1.svCanvasHeight,
            svCanvasWidth: config_1.svCanvasWidth,
        });
        var el = conEl.firstChild;
        var childNodes = el.childNodes;
        this.el = el;
        this.onSuccess = onSuccess;
        this.onFail = onFail;
        this.onRefresh = onRefresh;
        this.photo = photo;
        if (photo) {
            this.source = source;
        }
        var canvas = childNodes[0];
        var refreshIcon = childNodes[1];
        var block = childNodes[2];
        var sliderContainer = childNodes[3];
        var sliderMask = sliderContainer.childNodes[0];
        var text = sliderContainer.childNodes[1];
        var slider = sliderMask.childNodes[0];
        var sliderIcon = sliderMask.childNodes[0];
        this.canvas = canvas;
        this.block = block;
        this.sliderContainer = sliderContainer;
        this.refreshIcon = refreshIcon;
        this.slider = slider;
        this.sliderMask = sliderMask;
        this.sliderIcon = sliderIcon;
        this.text = text;
        this.canvasCtx = canvas.getContext('2d');
        this.blockCtx = block.getContext('2d');
        this.trail = [];
        this.initImg();
        this.bindEvents();
    }
    SlideVerify.prototype.initImg = function () {
        var _this = this;
        var img = utils_1.createImg(function () {
            var _a;
            // 随机创建滑块挖取的位置
            _this.x = utils_1.getRandomNumberByRange(config_1.L + 70, config_1.svCanvasWidth - (config_1.L + 10));
            _this.y = utils_1.getRandomNumberByRange(10 + config_1.r * 2, config_1.svCanvasHeight - (config_1.L + 10));
            // draw canvas 及 被抠出的 piece 留下的坑
            if (_this.source) {
                /* tsbug https://github.com/microsoft/TypeScript/issues/36133 */
                (_a = _this.canvasCtx).drawImage.apply(_a, __spreadArray(__spreadArray([img], _this.source), [0, 0, config_1.svCanvasWidth, config_1.svCanvasHeight]));
            }
            else {
                _this.canvasCtx.drawImage(img, 0, 0, config_1.svCanvasWidth, config_1.svCanvasHeight);
            }
            _this.canvasCtx.fillStyle = 'rgba(0, 0, 0, 0.35)';
            _this.canvasCtx.strokeStyle = 'rgba(255, 255, 255, 0.1)';
            draw_1.drawPiece(_this.canvasCtx, _this.x, _this.y);
            _this.canvasCtx.fill();
            draw_1.drawPieceInsideShadow(_this.canvasCtx, _this.x, _this.y);
            draw_1.drawBlock(img, _this.blockCtx, _this.x, _this.y);
        }, this.photo);
        this.img = img;
    };
    SlideVerify.prototype.clean = function () {
        this.canvasCtx.clearRect(0, 0, config_1.svCanvasWidth, config_1.svCanvasHeight);
        this.blockCtx.clearRect(0, 0, config_1.svCanvasWidth, config_1.svCanvasHeight);
        this.block.width = config_1.svCanvasWidth;
    };
    SlideVerify.prototype.bindEvents = function () {
        var _this = this;
        this.el.onselectstart = function () { return false; };
        this.refreshIcon.onclick = function () {
            _this.reset();
            typeof _this.onRefresh === 'function' && _this.onRefresh();
        };
        var originX, originY, trail = [], isMouseDown = false;
        var handleDragStart = function (e) {
            if (e instanceof MouseEvent) {
                originX = e.clientX;
                originY = e.clientY;
            }
            else if (e instanceof TouchEvent) {
                originX = e.touches[0].clientX;
                originY = e.touches[0].clientY;
            }
            isMouseDown = true;
        };
        var handleDragMove = function (e) {
            if (!isMouseDown)
                return false;
            var eventX = 0;
            var eventY = 0;
            if (e instanceof MouseEvent) {
                eventX = e.clientX;
                eventY = e.clientY;
            }
            else if (e instanceof TouchEvent) {
                eventX = e.touches[0].clientX;
                eventY = e.touches[0].clientY;
            }
            var moveX = eventX - originX;
            var moveY = eventY - originY;
            if (moveX < 0 || moveX + 38 >= config_1.svCanvasWidth)
                return false;
            _this.slider.style.left = moveX + 'px';
            // const blockLeft = (w - 40 - 20) / (w - 40) * moveX
            var blockLeft = moveX;
            _this.block.style.left = blockLeft + 'px';
            utils_1.addClass(_this.sliderContainer, styles.sliderContainer_active);
            _this.sliderMask.style.width = moveX + 12 + 'px';
            trail.push(moveY);
        };
        var handleDragEnd = function (e) {
            if (!isMouseDown)
                return false;
            isMouseDown = false;
            var eventX = 0;
            if (e instanceof MouseEvent) {
                eventX = e.clientX;
            }
            else if (e instanceof TouchEvent) {
                eventX = e.changedTouches[0].clientX;
            }
            if (eventX == originX)
                return false;
            utils_1.removeClass(_this.sliderContainer, styles.sliderContainer_active);
            _this.trail = trail;
            var _a = _this.verify(), spliced = _a.spliced, verified = _a.verified;
            if (spliced) {
                if (verified) {
                    _this.sliderIcon.childNodes[0].innerHTML = "<i class=\"fas fa-check\" aria-hidden=\"true\"></i>";
                    utils_1.addClass(_this.sliderContainer, styles.sliderContainer_success);
                    typeof _this.onSuccess === 'function' && _this.onSuccess();
                }
                else {
                    utils_1.addClass(_this.sliderContainer, styles.sliderContainer_fail);
                    _this.text.innerHTML = '再试一次';
                    _this.reset();
                }
            }
            else {
                _this.sliderIcon.childNodes[0].innerHTML = "<i class=\"fas fa-times\" aria-hidden=\"true\"></i>";
                utils_1.addClass(_this.sliderContainer, styles.sliderContainer_fail);
                typeof _this.onFail === 'function' && _this.onFail();
                setTimeout(function () {
                    _this.reset();
                }, 1000);
            }
        };
        this.slider.addEventListener('mousedown', handleDragStart);
        this.slider.addEventListener('touchstart', handleDragStart);
        this.block.addEventListener('mousedown', handleDragStart);
        this.block.addEventListener('touchstart', handleDragStart);
        document.addEventListener('mousemove', handleDragMove);
        document.addEventListener('touchmove', handleDragMove);
        document.addEventListener('mouseup', handleDragEnd);
        document.addEventListener('touchend', handleDragEnd);
    };
    SlideVerify.prototype.verify = function () {
        var arr = this.trail; // 拖动时y轴的移动距离
        var average = arr.reduce(utils_1.sum) / arr.length;
        var deviations = arr.map(function (x) { return x - average; });
        var stddev = Math.sqrt(deviations.map(utils_1.square).reduce(utils_1.sum) / arr.length);
        var left = parseInt(this.block.style.left);
        return {
            spliced: Math.abs(left - this.x) < 10,
            verified: stddev !== 0, // 简单验证下拖动轨迹，为零时表示Y轴上下没有波动，可能非人为操作
        };
    };
    SlideVerify.prototype.reset = function () {
        this.sliderContainer.className = styles.sliderContainer;
        this.sliderIcon.childNodes[0].innerHTML = "<i class=\"fas fa-bars fa-rotate-90\" aria-hidden=\"true\"></i>";
        this.slider.style.left = '0';
        this.block.style.left = '0';
        this.sliderMask.style.width = '0';
        this.clean();
        this.initImg();
    };
    return SlideVerify;
}());
exports.default = SlideVerify;
window.SlideVerify = SlideVerify;
