"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.drawPiece = void 0;
var config_1 = require("../config");
var PI = Math.PI;
function drawPiece(ctx, x, y) {
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.arc(x + config_1.l / 2, y - config_1.r + 2, config_1.r, 0.72 * PI, 2.26 * PI);
    ctx.lineTo(x + config_1.l, y);
    ctx.arc(x + config_1.l + config_1.r - 2, y + config_1.l / 2, config_1.r, 1.21 * PI, 2.78 * PI);
    ctx.lineTo(x + config_1.l, y + config_1.l);
    ctx.lineTo(x, y + config_1.l);
    ctx.arc(x + config_1.r - 2, y + config_1.l / 2, config_1.r + 0.4, 2.76 * PI, 1.24 * PI, true);
    ctx.lineTo(x, y);
    ctx.stroke();
}
exports.drawPiece = drawPiece;
