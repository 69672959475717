"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mobile = exports.L = exports.svCanvasHeight = exports.svCanvasWidth = exports.r = exports.l = exports.checkMobile = void 0;
var l = 42, // 滑块边长
r = 7.5, // 滑块半径
svCanvasWidth = 310, // canvas宽度
svCanvasHeight = 210, // canvas高度
L = l + r * 2 + 9; // 滑块实际边长
exports.l = l;
exports.r = r;
exports.svCanvasWidth = svCanvasWidth;
exports.svCanvasHeight = svCanvasHeight;
exports.L = L;
var mobile = checkMobile();
exports.mobile = mobile;
function calcPixel() {
    if (mobile) {
        exports.svCanvasWidth = svCanvasWidth = scalePixel(svCanvasWidth);
        exports.svCanvasHeight = svCanvasHeight = scalePixel(svCanvasHeight);
        exports.l = l = scalePixel(l);
        exports.r = r = scalePixel(r);
        exports.L = L = scalePixel(L);
    }
}
calcPixel();
function scalePixel(pixel) {
    // return pixel * window.devicePixelRatio / 1.5
    return pixel * 2;
}
function checkMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        // true for mobile device
        return true;
    }
    else {
        // false for not mobile device
        return false;
    }
}
exports.checkMobile = checkMobile;
