"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.drawBlock = void 0;
var drawPiece_1 = require("./drawPiece");
var config_1 = require("../config");
var utils_1 = require("../utils");
function drawBlock(img, ctx, x, y) {
    // 第一步 生成包含图像的 piece 方块
    ctx.lineWidth = 0.5;
    ctx.fillStyle = 'rgba(0, 0, 0, 0.35)';
    ctx.strokeStyle = 'rgba(253,255,29,0.7)';
    drawPiece_1.drawPiece(ctx, x, y);
    ctx.clip();
    ctx.globalCompositeOperation = 'source-over';
    // 设置图像及边长
    ctx.drawImage(img, 0, 0, config_1.svCanvasWidth, config_1.svCanvasHeight);
    var pieceY = y - config_1.r * 2 - 1;
    var ImageData = ctx.getImageData(x - 3, pieceY, config_1.L, config_1.L);
    ctx.canvas.width = config_1.L;
    ctx.putImageData(ImageData, 0, pieceY);
    // 第二步生成外shadow piece
    var shaodwCtx = utils_1.createCanvas(config_1.svCanvasWidth, config_1.svCanvasHeight);
    shaodwCtx.canvas.width = config_1.L;
    shaodwCtx.shadowColor = "black";
    shaodwCtx.shadowBlur = 6;
    shaodwCtx.shadowOffsetX = 2;
    shaodwCtx.shadowOffsetY = 5;
    drawPiece_1.drawPiece(shaodwCtx, 3, y);
    shaodwCtx.lineWidth = 1;
    shaodwCtx.fillStyle = 'rgba(0, 0, 0, 1)';
    shaodwCtx.strokeStyle = 'rgba(0, 0, 0, 1)';
    shaodwCtx.stroke();
    shaodwCtx.fill();
    var compositeCtx = utils_1.createCanvas(config_1.svCanvasWidth, config_1.svCanvasHeight);
    compositeCtx.canvas.width = config_1.L;
    compositeCtx.drawImage(shaodwCtx.canvas, 0, 0);
    compositeCtx.drawImage(ctx.canvas, 0, 0);
    ctx.drawImage(compositeCtx.canvas, 0, 0);
}
exports.drawBlock = drawBlock;
