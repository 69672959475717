"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.square = exports.sum = exports.removeClass = exports.addClass = exports.getRandomNumberByRange = void 0;
function getRandomNumberByRange(start, end) {
    return Math.round(Math.random() * (end - start) + start);
}
exports.getRandomNumberByRange = getRandomNumberByRange;
function addClass(tag, className) {
    tag.classList.add(className);
}
exports.addClass = addClass;
function removeClass(tag, className) {
    tag.classList.remove(className);
}
exports.removeClass = removeClass;
function sum(x, y) {
    return x + y;
}
exports.sum = sum;
function square(x) {
    return x * x;
}
exports.square = square;
