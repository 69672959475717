"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.drawPieceInsideShadow = void 0;
var drawPiece_1 = require("./drawPiece");
var config_1 = require("../config");
function drawPieceInsideShadow(ctx, x, y) {
    // 第一步生成一个 piece 图形模板
    var piece = document.createElement("canvas");
    piece.width = config_1.svCanvasWidth;
    piece.height = config_1.svCanvasHeight;
    var pieceCtx = piece.getContext("2d");
    pieceCtx.fillStyle = "white";
    drawPiece_1.drawPiece(pieceCtx, x, y);
    pieceCtx.lineWidth = 1;
    pieceCtx.strokeStyle = 'rgba(0, 0, 0, 1)';
    pieceCtx.stroke();
    pieceCtx["clip"]();
    // document.body.appendChild(piece);
    // 第二步生成 piece 外围黑边准备用于内投影
    var hole = document.createElement("canvas");
    var holeContext = hole.getContext("2d");
    hole.width = config_1.svCanvasWidth;
    hole.height = config_1.svCanvasHeight;
    //first, I draw a big black rect
    holeContext.fillStyle = "#000000";
    holeContext.fillRect(0, 0, config_1.svCanvasWidth, config_1.svCanvasHeight);
    //then I use the image to make an hole in it
    holeContext.globalCompositeOperation = "xor";
    drawPiece_1.drawPiece(holeContext, x, y);
    holeContext.lineWidth = 0;
    holeContext.fill();
    // 第三步生成内shadow
    var shadow = document.createElement("canvas");
    var shadowContext = shadow.getContext("2d");
    shadow.width = config_1.svCanvasWidth;
    shadow.height = config_1.svCanvasHeight;
    shadowContext.filter = "drop-shadow(0px 0px " + "5px #000000 ) ";
    // 默认 source-over 模式下叠加阴影，destination-out 模式...
    for (var i = 0; i < 4; i++) {
        shadowContext.drawImage(hole, 0, 0);
    }
    shadowContext.globalCompositeOperation = "destination-out";
    shadowContext.drawImage(hole, 0, 0);
    // 第四步应用shadow
    ctx.drawImage(shadow, 0, 0);
}
exports.drawPieceInsideShadow = drawPieceInsideShadow;
